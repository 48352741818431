import { useRef, useEffect, ChangeEvent } from 'react'
import { tokens } from '@scm/ui-tailwind'

export const useSearchBar = (
  setValue: (value: string) => void,
  autoFocusInput: boolean,
) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const formRef = useRef<HTMLFormElement>(null)

  const onClickCloseIcon = () => {
    setValue('')
    inputRef.current && (inputRef.current.value = '')
    formRef.current?.requestSubmit()
  }

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    inputRef.current && (inputRef.current.value = e.target.value)
  }

  useEffect(() => {
    if (
      window?.innerWidth >= Number(tokens.screens.md.slice(0, -2)) ||
      autoFocusInput
    )
      inputRef.current?.focus()
  }, [autoFocusInput])

  return { onClickCloseIcon, onChangeInput, inputRef, formRef }
}
