import React, { forwardRef, ForwardedRef } from 'react'
import { Icon } from '@scm/ui-component-icon'

import { SearchSectionProps } from './type'
import { searchBar } from './constants'

export const SearchSection = forwardRef<HTMLInputElement, SearchSectionProps>(
  (
    {
      classNames,
      value,
      variant = 'header',
      required = true,
      placeholder = 'Search',
      onClickCloseIcon,
      onChangeInput,
    }: SearchSectionProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => (
    <>
      <input
        ref={ref}
        name="q"
        className={searchBar('Input', { variant }, [classNames?.input])}
        placeholder={placeholder}
        title="Search"
        aria-label="Search"
        type="text"
        value={value}
        onChange={onChangeInput}
        required={required}
        data-testid="input"
      />

      {value && (
        <button
          className={searchBar('CloseButton')}
          type="button"
          aria-label="clear-button"
          onClick={onClickCloseIcon}
          data-testid="closeButton"
        >
          <Icon icon="Close" className={searchBar('CloseIcon')} />
        </button>
      )}

      <button
        className={searchBar('SearchButton', [classNames?.searchBtn])}
        type="submit"
        aria-label="Search"
        data-testid="searchButton"
      >
        Go
      </button>
    </>
  ),
)
